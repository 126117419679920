var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-chart", {
    ref: "echarts",
    attrs: { option: _vm.assignedOptions, "update-options": { notMerge: true } }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }