var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dashboard-new" },
    [
      _c(
        "SectionContainer",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.overall.loading,
              expression: "overall.loading"
            }
          ],
          staticStyle: { "margin-bottom": "15px" },
          attrs: { title: "整体概览" },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function() {
                return [
                  _c("div", { staticClass: "dashboard-sheader" }, [
                    _c("div"),
                    _c(
                      "div",
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: {
                              width: "100px",
                              "vertical-align": "middle"
                            },
                            attrs: { size: "small" },
                            on: { change: _vm.getOverallData },
                            model: {
                              value: _vm.overall.dateType,
                              callback: function($$v) {
                                _vm.$set(_vm.overall, "dateType", $$v)
                              },
                              expression: "overall.dateType"
                            }
                          },
                          [
                            _c("el-option", {
                              attrs: { label: "付款时间", value: 1 }
                            }),
                            _c("el-option", {
                              attrs: { label: "结算时间", value: 2 }
                            })
                          ],
                          1
                        ),
                        _c("MDatePicker", {
                          staticClass: "dashboard-sheader-date",
                          on: {
                            change: function($event) {
                              return _vm.dateChange($event, "overall")
                            }
                          }
                        })
                      ],
                      1
                    )
                  ])
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c("div", { staticStyle: { width: "55%", float: "left" } }, [
            _c(
              "div",
              { staticClass: "d-flex" },
              _vm._l(_vm.overall.blocks, function(item, index) {
                return _c("DataBlock", {
                  key: index,
                  staticClass: "flex-1",
                  attrs: {
                    title: item.title,
                    value: item.value,
                    tooltip: item.tooltip
                  }
                })
              }),
              1
            ),
            _c(
              "div",
              { staticStyle: { width: "100%", height: "240px" } },
              [
                _vm.overall.chartData
                  ? _c("LineChart", {
                      attrs: { options: _vm.overall.chartData }
                    })
                  : _vm._e()
              ],
              1
            )
          ]),
          _c(
            "div",
            {
              staticStyle: {
                width: "45%",
                float: "right",
                "padding-left": "20px"
              }
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    background: "#f6f6f6",
                    padding: "3px",
                    "margin-top": "20px"
                  }
                },
                [
                  _c(
                    "p",
                    {
                      staticStyle: {
                        "font-size": "15px",
                        margin: "15px 15px 0"
                      }
                    },
                    [_vm._v("客户概况")]
                  ),
                  _c(
                    "div",
                    { staticClass: "d-flex" },
                    _vm._l(_vm.overall.clientBlocks, function(item, index) {
                      return _c("DataBlock", {
                        key: index,
                        staticClass: "flex-1",
                        attrs: {
                          title: item.title,
                          value: item.value,
                          tooltip: item.tooltip
                        }
                      })
                    }),
                    1
                  )
                ]
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    background: "#f6f6f6",
                    padding: "3px",
                    "margin-top": "20px"
                  }
                },
                [
                  _c(
                    "p",
                    {
                      staticStyle: {
                        "font-size": "15px",
                        margin: "15px 15px 0"
                      }
                    },
                    [_vm._v("应用概况")]
                  ),
                  _c(
                    "div",
                    { staticClass: "d-flex" },
                    _vm._l(_vm.overall.appBlocks, function(item, index) {
                      return _c("DataBlock", {
                        key: index,
                        staticClass: "flex-1",
                        attrs: {
                          title: item.title,
                          value: item.value,
                          tooltip: item.tooltip
                        }
                      })
                    }),
                    1
                  )
                ]
              )
            ]
          )
        ]
      ),
      _c(
        "SectionContainer",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.cps.loading,
              expression: "cps.loading"
            }
          ],
          staticStyle: { "margin-bottom": "15px" },
          attrs: { title: "CPS推广" },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function() {
                return [
                  _c(
                    "div",
                    { staticClass: "dashboard-sheader" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "text", size: "small" },
                          on: {
                            click: function($event) {
                              return _vm.changeChartType("cps")
                            }
                          }
                        },
                        [
                          _vm._v(
                            "切换" +
                              _vm._s(
                                _vm.cps.chartType === "line" ? "占比" : "趋势"
                              ) +
                              "分析"
                          )
                        ]
                      ),
                      _c(
                        "div",
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: {
                                width: "100px",
                                "vertical-align": "middle"
                              },
                              attrs: { size: "small" },
                              on: {
                                change: function($event) {
                                  return _vm.getCpsData()
                                }
                              },
                              model: {
                                value: _vm.cps.dateType,
                                callback: function($$v) {
                                  _vm.$set(_vm.cps, "dateType", $$v)
                                },
                                expression: "cps.dateType"
                              }
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "付款时间", value: 1 }
                              }),
                              _c("el-option", {
                                attrs: { label: "结算时间", value: 2 }
                              })
                            ],
                            1
                          ),
                          _c("MDatePicker", {
                            staticClass: "dashboard-sheader-date",
                            on: {
                              change: function($event) {
                                return _vm.dateChange($event, "cps")
                              }
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c(
            "el-radio-group",
            {
              attrs: { size: "small" },
              on: {
                change: function($event) {
                  return _vm.getCpsData({}, true)
                }
              },
              model: {
                value: _vm.cps.mode,
                callback: function($$v) {
                  _vm.$set(_vm.cps, "mode", $$v)
                },
                expression: "cps.mode"
              }
            },
            [
              _c("el-radio-button", { attrs: { label: "platform" } }, [
                _vm._v("平台对比")
              ]),
              _c("el-radio-button", { attrs: { label: "client" } }, [
                _vm._v("客户对比")
              ]),
              _c("el-radio-button", { attrs: { label: "app" } }, [
                _vm._v("应用对比")
              ])
            ],
            1
          ),
          _c("ContrastFilter", {
            ref: "cpsFilter",
            staticStyle: { margin: "17px 0 10px" },
            attrs: {
              filters: _vm.cpsFilters,
              single: _vm.cps.chartType === "line" ? _vm.cps.mode : "",
              singleMultiSelect: ""
            },
            on: {
              change: function($event) {
                return _vm.getCpsData($event || {})
              }
            }
          }),
          _c(
            "div",
            { staticClass: "d-flex" },
            _vm._l(_vm.cps.blocks, function(item, index) {
              return _c("DataBlock", {
                key: index,
                staticClass: "dblock-selectable",
                class: { active: _vm.cps.blockActive === index },
                attrs: {
                  title: item.title,
                  value: item.value,
                  tooltip: item.tooltip
                },
                on: {
                  click: function($event) {
                    return _vm.cpsBlockChange(index)
                  }
                }
              })
            }),
            1
          ),
          _vm.cps.chartType === "line"
            ? _c(
                "div",
                { staticStyle: { width: "100%", height: "440px" } },
                [
                  _vm.cps.chartData
                    ? _c("LineChart", { attrs: { options: _vm.cps.chartData } })
                    : _vm._e()
                ],
                1
              )
            : _c(
                "div",
                { staticStyle: { width: "100%", height: "340px" } },
                [
                  _vm.cps.chartData
                    ? _c("PieChart", { attrs: { options: _vm.cps.chartData } })
                    : _vm._e()
                ],
                1
              )
        ],
        1
      ),
      _c(
        "SectionContainer",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.qy.loading,
              expression: "qy.loading"
            }
          ],
          staticStyle: { "margin-bottom": "15px" },
          attrs: { title: "权益推广" },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function() {
                return [
                  _c(
                    "div",
                    { staticClass: "dashboard-sheader" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "text", size: "small" },
                          on: {
                            click: function($event) {
                              return _vm.changeChartType("qy")
                            }
                          }
                        },
                        [
                          _vm._v(
                            "切换" +
                              _vm._s(
                                _vm.qy.chartType === "line" ? "占比" : "趋势"
                              ) +
                              "分析"
                          )
                        ]
                      ),
                      _c("MDatePicker", {
                        staticClass: "dashboard-sheader-date",
                        on: {
                          change: function($event) {
                            return _vm.dateChange($event, "qy")
                          }
                        }
                      })
                    ],
                    1
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c(
            "el-radio-group",
            {
              attrs: { size: "small" },
              on: {
                change: function($event) {
                  return _vm.getQyData({}, true)
                }
              },
              model: {
                value: _vm.qy.mode,
                callback: function($$v) {
                  _vm.$set(_vm.qy, "mode", $$v)
                },
                expression: "qy.mode"
              }
            },
            [
              _c("el-radio-button", { attrs: { label: "platform" } }, [
                _vm._v("平台对比")
              ]),
              _c("el-radio-button", { attrs: { label: "client" } }, [
                _vm._v("客户对比")
              ]),
              _c("el-radio-button", { attrs: { label: "app" } }, [
                _vm._v("应用对比")
              ]),
              _vm.qy.chartType === "pie"
                ? _c("el-radio-button", { attrs: { label: "sku" } }, [
                    _vm._v("SKU对比")
                  ])
                : _vm._e()
            ],
            1
          ),
          _c("ContrastFilter", {
            ref: "qyFilter",
            staticStyle: { margin: "17px 0 10px" },
            attrs: {
              platformType: "qy",
              filters: _vm.qyFilters,
              single: _vm.qy.chartType === "line" ? _vm.qy.mode : "",
              singleMultiSelect: ""
            },
            on: {
              change: function($event) {
                return _vm.getQyData($event || {})
              }
            }
          }),
          _c(
            "div",
            { staticClass: "d-flex" },
            _vm._l(_vm.qy.blocks, function(item, index) {
              return _c("DataBlock", {
                key: index,
                staticClass: "dblock-selectable",
                class: { active: _vm.qy.blockActive === index },
                attrs: {
                  title: item.title,
                  value: item.value,
                  tooltip: item.tooltip
                },
                on: {
                  click: function($event) {
                    return _vm.qyBlockChange(index)
                  }
                }
              })
            }),
            1
          ),
          _vm.qy.chartType === "line"
            ? _c(
                "div",
                { staticStyle: { width: "100%", height: "440px" } },
                [
                  _vm.qy.chartData
                    ? _c("LineChart", { attrs: { options: _vm.qy.chartData } })
                    : _vm._e()
                ],
                1
              )
            : _c(
                "div",
                { staticStyle: { width: "100%", height: "340px" } },
                [
                  _vm.qy.chartData
                    ? _c("PieChart", { attrs: { options: _vm.qy.chartData } })
                    : _vm._e()
                ],
                1
              )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }