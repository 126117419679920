import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.array.join.js";

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
function formatDate(date) {
  var MM = date.getMonth() + 1;
  if (MM < 10) MM = '0' + MM;
  var DD = date.getDate();
  if (DD < 10) DD = '0' + DD;
  return date.getFullYear() + '-' + MM + '-' + DD;
}

function getLastDate(n) {
  var date = new Date();
  var t = date.getTime();
  n && date.setTime(t - 3600 * 1000 * 24 * (n > 3 ? n - 1 : n));
  return formatDate(date);
}

export default {
  props: {
    size: {
      type: String,
      default: 'small'
    },
    init: {
      type: [Number, Array],
      // 0 | 1 | 7 | 30 | [start,end]
      default: 0
    }
  },
  data: function data() {
    var today = getLastDate(0);
    return {
      shortcut: null,
      // string,
      shortcuts: [{
        id: 0,
        text: '今日',
        value: today + ',' + today
      }, {
        id: 1,
        text: '昨日',
        value: getLastDate(1) + ',' + getLastDate(1)
      }, {
        id: 7,
        text: '7日',
        value: getLastDate(7) + ',' + today
      }, {
        id: 30,
        text: '30日',
        value: getLastDate(30) + ',' + today
      }],
      dateRange: null // string[]

    };
  },
  mounted: function mounted() {
    this.initDate();
  },
  methods: {
    initDate: function initDate() {
      var d = this.init;

      if (typeof d === 'number') {
        var item = this.shortcuts.find(function (a) {
          return a.id === d;
        });

        if (item) {
          this.shortcut = item.value;
          this.shortcutChange(item.value);
        }
      } else if (Array.isArray(d)) {
        this.dateRange = d;
        this.dateRangeChange(d);
      }
    },
    shortcutChange: function shortcutChange(e) {
      var dr = e.split(',');
      this.dateRange = dr;
      this.$emit('change', dr);
    },
    dateRangeChange: function dateRangeChange(e) {
      if (!e) return;
      this.$emit('change', e);
      var str = e.join(',');

      for (var i = 0, l = this.shortcuts.length; i < l; i++) {
        if (str === this.shortcuts[i].value) {
          this.shortcut = str;
          return;
        }
      }

      this.shortcut = null;
    }
  }
};