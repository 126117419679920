import _objectSpread from "/Volumes/workspace/project/twiboss/frontend/twi-super-admin/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.map.js";
//
//
//
//
import { use } from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { LineChart } from 'echarts/charts';
import { TitleComponent, TooltipComponent, LegendComponent, GridComponent } from 'echarts/components';
import VChart from 'vue-echarts';
use([CanvasRenderer, LineChart, TitleComponent, TooltipComponent, LegendComponent, GridComponent]);
export default {
  name: 'LineChart',
  components: {
    VChart: VChart
  },
  // provide: {
  //   [THEME_KEY]: "dark",
  // },
  props: {
    options: {
      type: Object,
      default: function _default() {
        return {
          legend: {
            orient: 'horizontal'
          },
          // horizontal | vertical，图例排版
          xAxis: {
            type: 'category',
            data: ['周一', '周二']
          },
          // 同ECharts的xAxis
          series: [] // [{ name, data: [1, 123] }]，同ECharts的series，只需定义每一项的name和data

        };
      }
    }
  },
  computed: {
    assignedOptions: function assignedOptions() {
      var theOpt = this.options;
      var yAxisItemDefault = {
        type: 'value',
        axisLine: {
          show: false
        },
        minInterval: 1,
        axisLabel: {
          color: '#6A707E',
          fontSize: 12
        },
        axisTick: {
          show: false
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: 'rgba(0, 0, 0, .04)'
          }
        }
      };
      var color = theOpt.color || ['#5A55C6', '#65A4F4', '#73CODD', '#A073DD', '#F9C858', '#F39675', '#ED6667', '#FF8A97', '#82C560', '#9CDFB5', '#999'];
      var series = theOpt.series || [];
      var legend = series[0] && series[0].name !== undefined ? _objectSpread({
        orient: 'horizontal',
        left: 'right',
        right: '0',
        top: 'top',
        data: series.map(function (a) {
          return a.name;
        }),
        icon: 'circle'
      }, theOpt.legend) : null;
      return {
        // title: {
        //   text: '',
        //   left: 'left',
        //   top: '0'
        // },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            lineStyle: {
              color: 'rgba(24, 144, 255, 0.4)'
            }
          },
          // appendToBody: true,
          confine: false
        },
        legend: legend,
        grid: _objectSpread({
          left: 'center',
          top: 'center',
          right: 0,
          bottom: 0,
          width: '86%',
          containLabel: true
        }, theOpt.grid),
        color: color,
        xAxis: Object.assign({
          type: 'category',
          nameLocation: 'center',
          axisLine: {
            show: false
          },
          axisLabel: {
            color: '#6A707E',
            fontSize: 12
          },
          axisTick: {
            show: false
          }
        }, theOpt.xAxis),
        yAxis: Array.isArray(theOpt.yAxis) ? theOpt.yAxis.map(function (a, i) {
          return _objectSpread(_objectSpread({}, yAxisItemDefault), {}, {
            axisLabel: {
              color: color[i]
            }
          }, a);
        }) : _objectSpread(_objectSpread({}, yAxisItemDefault), theOpt.yAxis),
        // dataZoom: [{
        //     startValue: '2014-06-01'
        // }, {
        //     type: 'inside'
        // }],
        series: series.map(function (a) {
          return _objectSpread({
            type: 'line',
            smooth: false,
            // symbolSize: 14,
            // itemStyle: {
            //   borderWidth: 2,
            //   color: a.itemStyle && a.itemStyle.color
            // },
            // lineStyle: {
            //   width: 2,
            //   shadowOffsetX: 1,
            //   shadowOffsetY: 8,
            //   shadowBlur: 20,
            //   shadowColor: 'rgba(24, 144, 255, 0.4)'
            // },
            areaStyle: {
              opacity: 0
            }
          }, a);
        })
      };
    }
  },
  mounted: function mounted() {
    window.addEventListener('resize', this.resizeTheChart, false);
  },
  beforeDestroy: function beforeDestroy() {
    window.removeEventListener('resize', this.resizeTheChart, false);
  },
  methods: {
    resizeTheChart: function resizeTheChart() {
      if (this.$refs.echarts) {
        this.$refs.echarts.resize();
      }
    }
  }
};