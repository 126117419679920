var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "msection-container" }, [
    _c("div", { staticClass: "msection-header" }, [
      _c("h2", { staticClass: "msection-title" }, [_vm._v(_vm._s(_vm.title))]),
      _c("div", { staticClass: "msection-header-slot" }, [_vm._t("header")], 2)
    ]),
    _c("div", { staticClass: "msection-content" }, [_vm._t("default")], 2)
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }