//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  props: {
    title: String
  }
};