import service from '@/utils/request';
/**
 * 报表-整体概览
 * @param {Object} data { time_type: 1-创建时间，2-结算时间, time_range: [] }
 */

export var reqDashboardOverview = function reqDashboardOverview(data) {
  return service({
    url: '/admin/dashboard/getOverview',
    method: 'post',
    data: data
  });
};
/**
 * 报表-CPS推广
 * @param {Object} data {
 *   type: 1-趋势/折线图，2-占比/饼图,
 *   time_type: 1-创建时间，2-结算时间,
 *   time_range: [],
 *   filter_type: 1-平台，2-客户，3-应用
 *   platform_id_ary, user_id_ary, app_id_ary, compare_type}
 */

export var reqDashboardCps = function reqDashboardCps(data) {
  return service({
    url: '/admin/dashboard/cps',
    method: 'post',
    data: data
  });
};
/**
 * 报表-权益推广
 * @param {Object} data {
 *   type: 1-趋势/折线图，2-占比/饼图,
 *   time_type: 1-创建时间，2-结算时间,
 *   time_range: [],
 *   filter_type: 1-平台，2-客户，3-应用，4-sku
 *   platform_id_ary, user_id_ary, app_id_ary, compare_type}
 */

export var reqDashboardQy = function reqDashboardQy(data) {
  return service({
    url: '/admin/dashboard/equityCard',
    method: 'post',
    data: data
  });
};