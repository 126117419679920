import _objectSpread from "/Volumes/workspace/project/twiboss/frontend/twi-super-admin/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.map.js";
//
//
//
//
import { use } from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { PieChart } from 'echarts/charts';
import { TitleComponent, TooltipComponent, LegendComponent } from 'echarts/components';
import VChart from 'vue-echarts';
import { stringifyNumber } from '@/utils/util.js';
use([CanvasRenderer, PieChart, TitleComponent, TooltipComponent, LegendComponent]);
export default {
  name: 'PieChart',
  components: {
    VChart: VChart
  },
  // provide: {
  //   [THEME_KEY]: "dark"
  // },
  props: {
    options: {
      type: Object,
      default: function _default() {
        return {
          sum: 12345,
          // 中心显示数值
          unit: '元',
          centerName: 'test',
          // 中心数值下方title
          legend: {
            orient: 'horizontal'
          },
          // horizontal | vertical，图例排版
          series: [{
            data: []
          }] // [{ name, value }]

        };
      }
    }
  },
  data: function data() {
    return {
      size: 400
    };
  },
  computed: {
    assignedOptions: function assignedOptions() {
      var theOpt = this.options;
      var size = this.size;

      var _stringifyNumber = stringifyNumber(theOpt.sum),
          value = _stringifyNumber.value,
          unit = _stringifyNumber.unit;

      var cTitle = '{value|' + value + '}';

      if (theOpt.unit) {
        cTitle += '{unit| ' + unit + theOpt.unit + '}';
      }

      var color = theOpt.color || ['#5A55C6', '#65A4F4', '#73CODD', '#A073DD', '#F9C858', '#F39675', '#ED6667', '#FF8A97', '#82C560', '#9CDFB5', '#999'];
      var series = theOpt.series || [];
      var legend = series[0] && series[0].data[0] && series[0].data[0].name !== undefined ? _objectSpread({
        orient: 'horizontal',
        itemWidth: 9,
        itemHeight: 9,
        itemGap: 18,
        left: size * 1.05,
        top: 'center',
        textStyle: {
          fontSize: 14
        },
        data: series[0].data.map(function (a) {
          return a.name;
        }),
        icon: 'circle'
      }, theOpt.legend) : null;
      return {
        title: _objectSpread({
          text: cTitle,
          left: size / 2,
          top: 'center',
          textAlign: 'center',
          textStyle: {
            rich: {
              value: {
                fontSize: size * 0.08,
                color: '#444',
                padding: [size * 0.1, 0, 0, 0]
              },
              unit: {
                fontSize: size * 0.05,
                color: '#444',
                padding: [size * 0.1, 0, 0, 0]
              }
            }
          },
          itemGap: -size * 0.18,
          subtext: theOpt.centerName,
          subtextStyle: {
            fontSize: size * 0.05,
            color: '#666'
          }
        }, theOpt.title),
        tooltip: _objectSpread({
          trigger: 'item',
          formatter: '{b} ({d}%)',
          // appendToBody: true,
          confine: true
        }, theOpt.tooltip),
        legend: legend,
        color: color,
        series: series.map(function (a) {
          return _objectSpread({
            type: 'pie',
            left: 'left',
            top: 'center',
            width: size,
            height: size,
            minAngle: 4,
            itemStyle: {
              borderColor: '#fff',
              borderWidth: 4
            },
            radius: ['60%', '80%'],
            label: {
              show: false
            }
          }, a);
        })
      };
    }
  },
  mounted: function mounted() {
    this.size = Math.min(this.$refs.echarts.getHeight(), this.$refs.echarts.getWidth());
    window.addEventListener('resize', this.resizeTheChart, false);
  },
  beforeDestroy: function beforeDestroy() {
    window.removeEventListener('resize', this.resizeTheChart, false);
  },
  methods: {
    resizeTheChart: function resizeTheChart() {
      var _this = this;

      var instance = this.$refs.echarts;
      if (!instance) return;
      instance.resize();
      this.$nextTick(function () {
        _this.size = Math.min(instance.getHeight(), instance.getWidth());
      });
    }
  }
};